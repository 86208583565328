<script setup lang="ts">
import InputText from "primevue/inputtext";
import Checkbox from "primevue/checkbox";
import MultiSelectSearchField from "@/components/shared/MultiSelectSearchField.vue";
import SelectSearchField from "@/components/shared/SelectSearchField.vue";
import type { SchoolDto } from "@/services/SchoolService";
import { computed, onMounted, ref, watch } from "vue";
import { useMenuStore } from "@/stores/MenuStore";
import { useDistrictStore } from "@/stores/DistrictStore";
import { Role, type District, type Menu } from "@/api/prisma-interfaces";
import { useI18n } from "vue-i18n";
import { useUserStore } from "@/stores/UserStore";
import { useOrganisationStore } from "@/stores/OrganisationStore";
import { InformationCircleIcon } from "@heroicons/vue/24/outline";
import LoadingSpinner from "@/components/shared/LoadingSpinner.vue";

interface SchoolForm {
  school: SchoolDto;
  create?: boolean;
}

// Helper function to filter IST schools based on IDs or a single ID
const filterIstSchools = (
  allSchools: { id: string; name: string; schoolCode: string }[],
  ids?: string[] | null,
  singleId?: string | null,
): { id: string; name: string; schoolCode: string }[] => {
  if (ids && ids.length > 0) {
    const idSet = new Set(ids);
    return allSchools.filter((s) => idSet.has(s.id));
  }
  if (singleId) {
    return allSchools.filter((s) => s.id === singleId);
  }
  return [];
};

const { t } = useI18n();
onMounted(async () => {
  menuStore.FETCH_MENUS();
  districtStore.FETCH_DISTRICTS();

  if (props.school.districtId) {
    selectedDistrict.value = await districtStore.GET_DISTRICT(
      props.school.districtId,
    );
  }

  if (props.school.menuId)
    selectedMenu.value = await menuStore.GET_MENU(props.school.menuId);
});

const menuStore = useMenuStore();
const districtStore = useDistrictStore();
const userStore = useUserStore();
const organisationStore = useOrganisationStore();

const props = defineProps<SchoolForm>();

const visible = computed({
  get: () => props.school.visible,
  set: (value: boolean) => emit("update:visible", value),
});

const selectedDistrict = ref<District>();
const selectedMenu = ref<Menu>();
const selectedSchools =
  ref<{ id: string; name: string; schoolCode: string }[]>();

const emit = defineEmits<{
  (event: "update:name", id: string): void;
  (event: "update:selectedDistrictId", id: string): void;
  (event: "update:selectedMenuId", id: string): void;
  (event: "update:visible", value: boolean): void;
  (event: "update:customerId", id: string | null): void;
  (event: "update:istSchoolId", id: string | null): void;
  (event: "update:istSchoolIds", ids: string[]): void;
}>();

watch(
  () => props.school,
  async (school) => {
    if (school.menuId) {
      selectedMenu.value = await menuStore.GET_MENU(school.menuId);
    } else {
      selectedMenu.value = undefined;
    }

    if (school.districtId) {
      selectedDistrict.value = await districtStore.GET_DISTRICT(
        school.districtId,
      );
    } else {
      selectedDistrict.value = undefined;
    }

    selectedSchools.value = filterIstSchools(
      organisationStore.IST_SCHOOLS,
      school.istSchoolIds,
      school.istSchoolId,
    );
  },
);

watch(
  () => selectedDistrict.value,
  async (district) => {
    if (district) {
      emit("update:selectedDistrictId", district.id);
    }
    if (district?.customerId) {
      await organisationStore.FETCH_IST_SCHOOLS(district?.customerId);

      selectedSchools.value = filterIstSchools(
        organisationStore.IST_SCHOOLS,
        props.school.istSchoolIds,
        props.school.istSchoolId,
      );
    }
  },
);

watch(
  () => selectedSchools.value,
  (schools) => {
    console.log("schools", schools);
    emit("update:istSchoolIds", schools?.map((s) => s.id) ?? []);
    emit("update:istSchoolId", null);
  },
);
</script>

<template>
  <form>
    <span class="block pl-2 text-sm font-semibold"
      >{{ t("school") }} {{ t("name") }} *
    </span>
    <input-text
      :model-value="school.name"
      :placeholder="$t('name')"
      class="w-full border-0 ring-slate-300"
      @update:model-value="$emit('update:name', $event || '')"
    />

    <div class="mt-5">
      <span class="block pl-2 text-sm font-semibold"
        >{{ $t("district") }} *</span
      >
      <select-search-field
        v-model="selectedDistrict"
        :search-items="districtStore.DISTRICTS"
        item-type="district"
        class="w-full"
        @update:model-value="
          () => {
            if (selectedDistrict?.id) {
              emit('update:selectedDistrictId', selectedDistrict.id);
            }
          }
        "
      />
    </div>

    <div class="mt-5">
      <span class="block pl-2 text-sm font-semibold">{{ t("menu") }} *</span>
      <select-search-field
        v-model="selectedMenu"
        :search-items="menuStore.MENUS"
        item-type="menu"
        class="w-full"
        @update:model-value="
          () => {
            if (selectedMenu?.id) {
              emit('update:selectedMenuId', selectedMenu.id);
            }
          }
        "
      />
    </div>

    <div class="mt-5 flex items-center">
      <Checkbox v-model="visible" :binary="true" />
      <label for="visible" class="ml-3 text-sm">{{
        t("visible_for_public")
      }}</label>
    </div>

    <div class="mt-5">
      <div class="flex flex-row items-center gap-1">
        <span class="block pl-2 text-sm font-semibold"
          >{{ $t("connect_to_ist_home_app") }}
        </span>
        <LoadingSpinner
          v-if="organisationStore.$state.loading.FETCH_IST_SCHOOLS"
          class="h-5 w-5"
        />
      </div>
      <div class="flex flex-row items-center justify-start gap-1">
        <InformationCircleIcon class="h-4 w-4" />
        <span class="block text-xs text-slate-800">{{
          $t("school_connect_info")
        }}</span>
      </div>
      <multi-select-search-field
        v-model="selectedSchools"
        :search-items="organisationStore.IST_SCHOOLS"
        :customer-id="selectedDistrict?.customerId"
        item-type="ist_school"
        class="w-full"
      />
    </div>

    <div v-if="userStore.currentUser?.role === Role.superAdmin" class="mt-5">
      <span class="block pl-2 text-sm font-semibold"
        >{{ $t("customer_id") }}
      </span>

      <div class="flex flex-row items-center justify-start gap-1">
        <InformationCircleIcon class="h-4 w-4" />
        <span class="block text-xs text-slate-800">{{
          $t("school_customer_id_info")
        }}</span>
      </div>
      <input-text
        :model-value="school.customerId"
        :placeholder="$t('customer_id')"
        class="w-full border-0 ring-slate-300"
        @update:model-value="$emit('update:customerId', $event || null)"
      />
    </div>
  </form>
</template>
